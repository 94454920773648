.dashboard {
  text-align: left;
  background-image: url('../images/Dashboard.jpg');
  background-size: cover;
  background-position: top center;

  &__content {
    padding: 20px;
    width: 50%;
    min-width: 500px;
  }
}

@media only screen and (max-width: @screen-sm) {
  .dashboard__content {
    width: 100%;
    min-width: 0px;
  }
}
