.dicomViewer {
  height: 100%;
  min-height: 600px;
  width: 100%;
  transform: rotate(180deg);
  margin: 0 16px;

  .layerContainer {
    border: 2px solid @primary-color;
    position: relative;
    padding: 0;
    margin: auto;
    text-align: center;
    box-sizing: content-box;
  }

  &__header {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 120px;
  }

  .imageLayer {
    position: absolute;
    left: 0px;
  }

  .drawDiv {
    position: absolute;
    pointer-events: none;
  }
}
